import React from "react";
import Layout from "../components/layout";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import queryString from "query-string";
import {useLocation} from "@reach/router";
import createUploadLink from "apollo-upload-client/public/createUploadLink";
import SingleEntryV2 from "../components/singleEntryV2";

const readGetParams = (query) => {
    const fallback = {id: 1, start: 0};
    if (query) {
        return queryString.parse(query);
    }
    return fallback;
};

const cache = new InMemoryCache();
const client = new ApolloClient({
    cache,
    link: createUploadLink({
        uri: process.env.GATSBY_STAPI
    })
});

const Entry = () => {

    const location = useLocation();
    const value = (location.search && readGetParams(location.search)) || {id: 1, start: 0};

    return (
        <ApolloProvider client={client}>
            <Layout>
                <SingleEntryV2 id={value.id} start={value.start} />
            </Layout>
        </ApolloProvider>

    );
}

export default Entry;

import {gql, useLazyQuery} from "@apollo/client";
import React, {useEffect} from "react";
import Link from "gatsby-link";

function SingleEntryV2(prop) {

    const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
    query GetRecord($id: ID!) {
        record (
        id: $id
        ) {
            id
            title
            artistFirst
            artistSecond
            songA
            songB
            label
            comment
            category {
                id
            }
            sub_category {
                id
            }
            covers {
                url
            }
        }
    }`,
        {
            variables: {
                id: prop.id
            }
        }
    )
    useEffect(() => {
        loadExpenseStatus( {
            variables: {
                date: data
            }
        })
    }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    function changeImage(id, covers) {
        console.log(covers)
        if (covers.length >= 2) {
            for (let i = 0; i < covers.length; i++) {
                if (document.getElementById(id).src == covers[i].url) { //process.env.GATSBY_STAPI_GRAPHQL +
                    if (i+1 < covers.length) {
                        document.getElementById(id).src = covers[i+1].url //process.env.GATSBY_STAPI_GRAPHQL +
                        return
                    } else {
                        document.getElementById(id).src = covers[0].url //process.env.GATSBY_STAPI_GRAPHQL +
                        return;
                    }
                }
            }
        }
    }

    return (
        <div className="py-16 bg-lightGrey overflow-hidden lg:py-12">
            {data !== undefined && (
                <div>
                    <div className="text-right mx-10 lg:mx-20">
                        <Link
                            to={`/page?category=${data.record.category.id}&sub=${data.record.sub_category.id}&start=${prop.start}`}
                            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                            <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                            Zurück zur Galerie
                        </Link>
                    </div>

                    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:mt-10 lg:px-8 lg:max-w-7xl">
                        <div className="relative">
                            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8">
                                <div className="lg:col-start-2 lg:inset-x-0 lg:top-0">
                                    <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                        { ( data.record.artistFirst != null && data.record.artistFirst != "" ) ? data.record.artistFirst : "" }
                                        { ( data.record.artistSecond != null && data.record.artistSecond != "" ) ? " | " + data.record.artistSecond : "" }
                                    </h3>
                                    <h2>
                                        { ( data.record.title != null && data.record.title != "" ) ? data.record.title : "" }
                                    </h2>


                                    <dl className="mt-10 space-y-10">
                                        { ( data.record.songA != null && data.record.songA != "" ) && (
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <div className="flex items-center justify-center h-8 w-8 rounded-md bg-green-dark text-white">
                                                        A
                                                    </div>
                                                </div>
                                                <div className="ml-4">
                                                    <dt className="text-lg font-medium text-gray-900">
                                                        { data.record.songA != "" ? "" + data.record.songA : "" }
                                                    </dt>
                                                </div>
                                            </div>
                                        )}
                                        { ( data.record.songB != null && data.record.songB != "" ) && (
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <div className="flex items-center justify-center h-8 w-8 rounded-md bg-green-dark text-white">
                                                        B
                                                    </div>
                                                </div>
                                                <div className="ml-4">
                                                    <dt className="text-lg font-medium text-gray-900">
                                                        { data.record.songB != "" ? "" + data.record.songB : "" }
                                                    </dt>
                                                </div>
                                            </div>
                                        )}
                                    </dl>

                                    <p className="mt-3 text-lg text-gray-500">
                                        { ( data.record.label != null && data.record.label != "" ) ? data.record.label : "" }
                                    </p>

                                    <p className="mt-3 text-lg text-gray-500">
                                        { ( data.record.comment != null && data.record.comment != "" ) ? data.record.comment : "" }
                                    </p>

                                </div>

                                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                                    { data.record.covers[0] != undefined && ( //process.env.GATSBY_STAPI_GRAPHQL +
                                        <img className="relative mx-auto shadow-lg rounded-lg" width="490"
                                             id={data.record.id}
                                             src={data.record.covers[0].url}
                                             alt=""/>
                                    )}
                                    { data.record.covers[0] != undefined && data.record.covers.length > 1 && (
                                        <nav className="mt-10 px-4 flex items-center justify-between sm:px-0">
                                            <div className="-mt-px w-0 flex-1 flex">
                                                <div
                                                    onClick={() => changeImage(data.record.id, data.record.covers)}
                                                    className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                                                    <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                                    </svg>
                                                    vorherige Abbildung
                                                </div>
                                            </div>
                                            <div className="-mt-px w-0 flex-1 flex justify-end">
                                                <div
                                                    onClick={() => changeImage(data.record.id, data.record.covers)}
                                                    className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                                                    nächste Abbildung
                                                    <svg className="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </nav>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </div>
)
}

export default SingleEntryV2;
